<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Customer details" @close-sidebar="closeSidebar">
                <customer-details
                    :uuid="uuid"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-customers container">
            <div class="row">
                <div class="col-12 heading-box">
                    <h1 class="heading">Customers</h1>

                    <div class="dropdown-select-box">
                        <span>Include inactive:</span>

                        <app-dropdown-select
                            v-model="filter.include_inactive"
                            :options="yes_no_options"

                            key-value="value"
                            key-title="title"

                            @change="onChangeIncludeInactive"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <app-loader v-if="loading" fixed-on="desktop" />

                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-table
                        class="customers-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :clickable="true"
                        :selected-row="uuid"

                        :cols="cols"
                        :rows="customers"

                        @row-click="onCustomersRowClick"
                        @row-click-mobile="onCustomerDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import customerDetails from '@/views/customer-details'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appDropdownSelect from '@/components/app-dropdown-select'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },

    components: {
        baseLayout,
        appSidebarDetails,

        customerDetails,

        appLoader,
        appError,
        appTable,
        appDropdownSelect,
        appPagination,
    },

    data() {
        return {
            customers: [],

            filter: {
                word: '',
                previous: '',

                include_inactive: false,

                sort: {
                    field: 'CreatedAt',
                    order: 'desc', // asc, desc
                },
            },

            sort: [
                { field: 'FirstName',    title: 'Name'              },
                { field: 'Active',       title: 'Status'            },
                { field: 'CreatedAt',    title: 'Registration date' },
                { field: 'Email',        title: 'Email'             },
            ],

            cols: [
                { key: 'name',         title: 'Name',           sort: { field: 'FirstName' }, highlight: true,                 },
                { key: 'ExternalRef1', title: 'External Ref# 1'                                                                },
                { key: 'Email',        title: 'Email',          sort: { field: 'Email'     }                                   },
                { key: 'MobileNumber', title: 'Contact Number',                                                                },
                { key: 'status',       title: 'Status',         sort: { field: 'Active'    },                  type: 'status', },
                { key: 'registration', title: 'Created At',     sort: { field: 'CreatedAt' }                                   },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            uuid: null,

            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        init(to, from) {
            const getCustomers = () => { this.getCustomers(to.params.page) }

            if (from.name && /^customer(-|$)/i.test(from.name)) {
                this.loadCustomersFilter().then(getCustomers).catch(getCustomers)
            } else {
                this.$store.dispatch('clearCustomersFilter').then(getCustomers).catch(getCustomers)
            }
        },

        loadCustomersFilter() {
            return this.$store.dispatch('loadCustomersFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getCustomers(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter,
            }

            let params = {
                'SPID': this.current_spid,

                'IncludeInactive': filter.include_inactive,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? true : false,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            /**
             * Sometimes the API sends "CreatedAt" field in nanoseconds, sometimes in seconds
             */

            const billion = Math.pow(10, 9)

            /**
             * Number of milliseconds passed from Jan 01 1970 00:00:00 to Jan 01 2100 00:00:00
             */
            const milliseconds = Date.UTC(2100, 0)

            /**
             * Number of seconds passed from Jan 01 1970 00:00:00 to Jan 01 2100 00:00:00
             */
            const seconds = milliseconds / 1000

            this.$store.dispatch('api_customer/FindCustomersPaginated', params)
                .then(response => {
                    if (!response.Customers || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.customers = response.Customers.map(customer => ({
                        uuid: customer.UUID,

                        name: this.fullname(customer.FirstName, customer.LastName),

                        Email: customer.Email,

                        MobileNumber: customer.MobileNumber,

                        status: {
                            status: customer.Active ? 'success' : 'danger', // _table-tag-colors.scss
                            text: customer.Active ? 'Active' : 'Inactive',
                        },

                        ExternalRef1: customer.ExternalRef1,

                        registration: customer.CreatedAt
                            ? customer.CreatedAt < seconds ? formatDateNano(customer.CreatedAt * billion) : formatDateNano(customer.CreatedAt)
                            : '?',
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.customers = []

                    this.loading = false
                })
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getCustomers(this.pagination.page, { word: search.word })
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getCustomers()
        },

        onPageChange(page) {
            this.getCustomers(page)
        },

        /**
         * Customer Details
         */
        onCustomersRowClick(customer) {
            if (this.uuid == customer.uuid) {
                this.closeSidebar()
            } else {
                this.uuid = customer.uuid
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.uuid = null
            this.$emit('close-sidebar')
        },

        onCustomerDetails(customer) {
            this.$router.push({ name: 'customer-details', params: { uuid: customer.uuid } })
        },

        onChangeIncludeInactive() {
            this.getCustomers(1)
        },
    },

    mounted() {
        if (!this.customers) {
            if (this.$route.params && this.$route.params.page) {
                this.getCustomers(to.params.page)
            } else {
                this.getCustomers(1)
            }
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        yes_no_options() {
            return [
                {
                    value: true,
                    title: 'Yes',
                },
                {
                    value: false,
                    title: 'No',
                },
            ]
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-customers {
    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        h1 {
            &.heading {
                margin: 0 16px 0 0;
            }
        }

        .dropdown-select-box {
            display: flex;
            align-items: center;

            min-width: 0;

            & > span {
                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);

                @include text-overflow();
            }

            .app-dropdown-select {
                width: 100px;
            }
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .customers-table {
        @include table-cols-width((250px, 175px, 250px, 180px, 125px, 175px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-customers {
        .customers-table {
            @include table-cols-width((155px, 100px, 155px, 110px, 80px, 125px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-customers {
        .heading-box {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 32px;

            h1 {
                &.heading {
                    margin: 0 0 16px 0;
                }
            }
        }

        .customers-table {
            @include table-cols-width-mobile((90px, 185px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>